import React, { FC } from 'react';
import clsx from 'clsx';
import { DialogVideoProps } from "@/components/dialog-container/components/DialogVideo/DialogVideo.props";
import { useStyles } from "@/components/dialog-container/components/dialog-messages.component";
import { DialogMessageContainer } from "@/components/dialog-container/components/DialogContainer/DialogMessageContainer";
import { DialogAvatar } from "@/components/dialog-container/components/DialogAvatar/DialogAvatar";
import UploadVideoItem from "@/components/base/upload-video-item";
import { formatAMPM } from "@/helpers/helpers";


export const DialogVideo: FC<DialogVideoProps> = ({
    messageId,
    videoData,
    createdAt,
    gender,
    payForDialogVideo,
    senderAvatar,
}) => {
    const classes = useStyles();

    return (
        <DialogMessageContainer id={messageId}>
            <DialogAvatar avatar={senderAvatar} />
            <div
                className={clsx(classes[gender], classes.leftMessageBackground, classes.messageWrapper)}
            >
                <div className={classes.messageBody}>
                    <div className={classes.profileSlideWrapper}>
                        <UploadVideoItem
                            id={videoData.id}
                            isExpended={false}
                            thumbnail={videoData?.thumbnail}
                            isPrivate={true}
                            withoutIcon={true}
                            isEditable={false}
                            origin={videoData?.origin}
                            handleClick={videoData.paidAt ? undefined : payForDialogVideo}
                            itemContainerStyle={classes.dropzoneVideo}
                        />
                    </div>
                </div>

                <div className={classes.time}>{formatAMPM(createdAt)}</div>
            </div>
        </DialogMessageContainer>
    );
};
