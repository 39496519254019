import { DialogMessage } from "@/types/user-profile.types";

export const dialogsSortByPinned = (a: DialogMessage, b: DialogMessage) => {
  if (a.pinRanked !== null && a.pinRanked !== undefined && b.pinRanked !== null && b.pinRanked !== undefined) {
    return a.pinRanked - b.pinRanked;
  }

  if (a.pinRanked !== null && a.pinRanked !== undefined && (b.pinRanked === null || b.pinRanked === undefined)) {
    return -1;
  } else if ((a.pinRanked === null || a.pinRanked === undefined) && b.pinRanked !== null && b.pinRanked !== undefined) {
    return 1;
  }

  return 0;
};

export const getLargestPinNumber = (dialogs: DialogMessage[]): number => {
  let largestPin = -1;
  for (const dialog of dialogs) {
    if (Number.isFinite(dialog.pinRanked)) {
      largestPin = Math.max(largestPin, dialog.pinRanked);
    }
  }

  return largestPin === -1 ? null : largestPin;
};

export const findIsDialogPinned = (dialog: DialogMessage) => !dialog.pinRanked