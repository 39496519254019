import React, {FC} from 'react';
import clsx from 'clsx';
import { Gallery } from "react-photoswipe-gallery";
import { useStyles } from "@/components/dialog-container/components/dialog-messages.component";
import { DialogMessageContainer } from "@/components/dialog-container/components/DialogContainer/DialogMessageContainer";
import { DialogAvatar } from "@/components/dialog-container/components/DialogAvatar/DialogAvatar";
import ProfileUserSlider from "@/components/sliders/profile-user-slider.component";
import { formatAMPM } from "@/helpers/helpers";
import { DialogPhotoProps } from "@/components/dialog-container/components/DialogPhoto/DialogPhoto.props";

export const DialogPhoto: FC<DialogPhotoProps> = ({
  messageId,
  photoData,
  senderAvatar,
  createdAt,
  gender,
  windowWidth,
  windowHeight,
  payForDialogPhoto,
  isClient,
}) => {
  const classes = useStyles();
  const isPaid = photoData.paidAt;

  return (
      <DialogMessageContainer id={messageId} onClick={isPaid || isClient ? undefined : payForDialogPhoto}>
        <DialogAvatar avatar={senderAvatar} />
        <div className={clsx(classes[gender], classes.leftMessageBackground, classes.messageWrapper)}>
          <div className={classes.messageBody}>
            {isPaid ? (
              <div className={classes.profileSlideWrapper}>
                <Gallery>
                  <ProfileUserSlider
                      defaultValue={[photoData]}
                      windowHeight={windowHeight - 90}
                      windowWidth={windowWidth}
                  />
                </Gallery>
              </div>
            ) : (
              <img alt={`${photoData?.small}`} src={photoData?.small}/>
            )}
          </div>
          <div className={classes.time}>{formatAMPM(createdAt)}</div>
        </div>
      </DialogMessageContainer>
  );
};
