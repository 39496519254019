import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { CSSProperties, useCallback, useRef, useMemo, useEffect } from 'react';
import { DeleteIconStyled } from '../dialogs/upload-photo-file.dialog.component';
import { StyleProps } from '../layouts/upload-photo.layout';
import { CircularProgress } from '@mui/material';
import playVideo from '@/assets/images/play-video.png';
const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  dropzone: ({ isExpended }) => ({
    cursor: 'pointer',
    color: theme.palette.radioSelected.main,
    outline: 'none',
    margin: 12,
    borderRadius: isExpended ? 15 : 6,
    width: isExpended ? 180 : 86,
    height: isExpended ? 180 : 86,
    backgroundColor: theme.palette.info.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  innerZone: {
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    borderRadius: 'inherit',
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  withAfter: {
    position: 'relative',
    '&::after': {
      content: '""',
      backgroundImage: `url(${playVideo})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1,
      opacity: 0.7,
    },
  },
  dropIcon: {
    fontSize: '2.7rem',
  },
  videoType: {
    borderRadius: 10,
    color: '#fff',
    fontSize: 12,
    fontWeight: 600,
    padding: '2px 6px',
  },
  publicVideo: {
    backgroundColor: theme.palette.success.main,
  },
  privateVideo: {
    backgroundColor: theme.palette.primary.main,
  },
}));

type UploadVideoItemProps = {
  id: string;
  isExpended: boolean;
  thumbnail: string;
  origin: string;
  paidAt?: string | null;
  profileId?: string | null;
  handleRemoveFile?: (id: string) => void;
  handleSelect?: (file: unknown) => void;
  isPrivate: boolean;
  isEditable: boolean;
  withoutIcon?: boolean;
  handleClick?: ({
    id,
    origin,
    paidAt,
    profileId,
    thumbnail,
    open,
  }: {
    id: string;
    origin: string;
    paidAt: string | null;
    profileId: string | null;
    thumbnail?: string;
    open: () => void;
  }) => void;
  itemContainerStyle?: string
};

const UploadVideoItem = ({
  id,
  isExpended,
  isEditable,
  thumbnail,
  origin,
  paidAt,
  profileId,
  handleRemoveFile,
  handleSelect,
  isPrivate,
  withoutIcon,
  handleClick,
  itemContainerStyle,
}: UploadVideoItemProps) => {
  const videoRef = useRef(null);

  const overridedHandleSelect = (file) => {
    typeof handleSelect === 'function' && handleSelect(file);
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement && videoRef.current) {
        videoRef.current.pause();
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange); // Safari
    document.addEventListener('mozfullscreenchange', handleFullscreenChange); // Firefox
    document.addEventListener('MSFullscreenChange', handleFullscreenChange); // IE/Edge

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, []);

  const handleOpenFullScreen = useCallback(() => {
    if (videoRef.current) {
      const videoElement = videoRef.current;

      if (videoElement.requestFullscreen) {
        videoElement.requestFullscreen();
      } else if (videoElement.mozRequestFullScreen) {
        // Firefox
        videoElement.mozRequestFullScreen();
      } else if (videoElement.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        videoElement.webkitRequestFullscreen();
      } else if (videoElement.msRequestFullscreen) {
        // IE/Edge
        videoElement.msRequestFullscreen();
      }

      videoElement.play();
    }
  }, []);

  const classes = useStyles({ isExpended, isEditable });

  const getPosition = () => {
    if (isExpended) return 10;
    if (!isExpended && isEditable) return 10;
    if (!isExpended) return 0;
  };

  const stylesOverrides = {
    closeIcon: {
      position: 'absolute',
      right: getPosition(),
      top: getPosition(),
      zIndex: 1000,
    },
  };

  const getIcon = useCallback(() => {
    if (withoutIcon) return null;

    if (isExpended || isEditable) return <DeleteIconStyled />;

    return (
      <span className={clsx(classes.videoType, isPrivate ? classes.privateVideo : classes.publicVideo)}>
        {isPrivate ? 'private' : 'public'}
      </span>
    );
  }, [classes.videoType, classes.privateVideo, classes.publicVideo, isEditable, isExpended, isPrivate, withoutIcon]);

  const handleRemove = useCallback(
    (id: string) => {
      if (handleRemoveFile) {
        handleRemoveFile(id);
      }
    },
    [handleRemoveFile]
  );

  return (
    <div
      key={origin}
      style={{ position: 'relative' }}
      onClick={() =>
        !thumbnail
          ? () => {}
          : overridedHandleSelect({
              id,
              origin,
              thumbnail,
              private: isPrivate,
            })
      }
    >
      <div className={clsx(classes.dropzone, itemContainerStyle)}>
        <div
          onClick={
            !thumbnail
              ? () => {}
              : handleSelect
              ? () => {}
              : handleClick
              ? () => handleClick({ id, origin, paidAt, profileId, thumbnail, open: handleOpenFullScreen })
              : () => handleOpenFullScreen()
          }
          className={clsx(classes.innerZone, !handleSelect && classes.withAfter)}
          style={
            !origin
              ? {
                  backgroundImage: `url("${thumbnail}")`,
                  opacity: 0.7,
                }
              : {
                  minHeight: '100px',
                  maxHeight: '180px',
                }
          }
        >
          {thumbnail ? (
            <video
              ref={videoRef}
              src={origin}
              controls={false}
              poster={thumbnail}
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'contain',
                opacity: 1,
              }}
            />
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>
      <div style={stylesOverrides.closeIcon as CSSProperties} onClick={() => handleRemove(id)}>
        {getIcon()}
      </div>
    </div>
  );
};

export default UploadVideoItem;
