import clsx from "clsx";
import profilePicture from "@/assets/images/demo-avatar.jpeg";
import React, {FC, memo, useCallback} from "react";
import { useStyles } from "@/components/dialog-container/components/dialog-messages.component";
import { DialogMessageContainerProps } from "@/components/dialog-container/components/DialogContainer/DialogMessageContainer.props";

export const DialogMessageContainer: FC<DialogMessageContainerProps> = ({
    children,
    id,
    isClient,
    onClick,
}) => {
    const classes = useStyles();

    return (
        <div
            className={clsx(classes.ownMessage, isClient ? classes.rightMessage : classes.leftMessage)}
            key={id}
            onClick={onClick}
        >
            {children}
        </div>
    )
}