import { useCallback, useEffect, useMemo, useState } from 'react';
import BaseCard from '@/components/base/base-card.component';
import { useContainerWidth } from '@/hooks';
import { Container, Divider, Theme, Typography, ToggleButtonGroup, ToggleButton, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import BaseButton from '@/components/base/button.component';
import CreditCard from './components/CreditCard';
import CreditsService from '@/services/credits.service';
import { useBinarySwitcher } from '@/hooks/useBinarySwitcher';
import { useAppSelector } from "@/app/hooks";
import DialogBuyCredits from '@/components/dialogs/buy-credits.dialog';
import DialogPriceList from '@/components/dialogs/price-list.dialog';
import { ReactComponent as PriceListIcon } from '@/assets/icons/price_list.svg';

import { ISettings } from "@/types/user-profile.types";

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    zIndex: 1000,
    marginTop: 40,
    [theme.breakpoints.down('xl')]: {
      marginTop: 24,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 14,
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginBottom: 20,
    },
  },

  cardWrapper: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  bodyWrapper: {
    paddingLeft: '10px',
    paddingTop: '65px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      paddingTop: '33px',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingTop: '18px',
    },
  },

  creditPackWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },

  creditPackImage: {
    width: 158,
    height: 194,
    position: 'absolute',
    borderRadius: 10,

    top: 'calc(50% - 97px)',
    left: '-10px',

    [theme.breakpoints.down('sm')]: {
      width: 120,
      height: 114,
    },
  },

  infoBlock: {
    overflow: ' hidden',
    position: 'relative',
    height: '100%',
    marginLeft: '169px',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    fontSize: '26px',
    lineHeight: '60px',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '124px',
      lineHeight: '45px',
    },
  },

  name: {
    marginTop: 12,
  },

  price: {
    display: 'flex',
    fontWeight: '600',
    fontSize: '23px',
    alignItems: 'center',
    lineHeight: '84px',

    [theme.breakpoints.down('xl')]: {
      fontSize: '16px',
    },

    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '23px',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '17px',
    },
  },

  verticalDivider: {
    height: 24,
    width: 2,
    backgroundColor: '#000',
    margin: '0 12px',

    [theme.breakpoints.down('xl')]: {
      margin: '0 6px',
    },
  },

  actualPrice: {
    color: '#503EB6',
  },

  oldPrice: {
    color: '#808080',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '84px',
    textDecoration: 'line-through',
    marginLeft: 7,

    [theme.breakpoints.down('xl')]: {
      fontSize: '13px',
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
  },

  buyButton: {
    height: 42,
    width: 140,
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important',
  },

  sale: {
    width: '194px',
    height: '43px',
    background: '#EA4160',
    transform: 'rotate(45deg)',
    color: '#fff',

    position: 'absolute',
    right: '-60px',
    top: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '18px',
  },
  icon: {
    paddingLeft: '4px',
    paddingRight: '4px',
    width: 20,
    height: 20,
  },
  priceListBtn: {
    color: `${theme.palette.radioSelected.main} !important`,
    width: '100%',
    maxWidth: 140,
  },
  priceListTextStyle: {
    fontWeight: '600 !important',
    fontSize: '14px !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '10px !important',
    },
  },

  currencySwitcherWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '65px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '-40px',
    },
  },
  currencySwitcherLeftButton: {
    paddingLeft: '20px!important',
    paddingRight: '20px!important',
    borderTopLeftRadius: '50px!important',
    borderBottomLeftRadius: '50px!important',
  },
  currencySwitcherRightButton: {
    borderTopRightRadius: '50px!important',
    borderBottomRightRadius: '50px!important',
    paddingLeft: '20px!important',
    paddingRight: '20px!important',
  }
}));

const CURRENCIES_LIST = {
  eur: 'eur',
  usd: 'usd'
}

const ACQUIRINGS_FOR_EUR = [
    'netvalve_eur',
    'inoviopay3ds2_eur',
    'flexpay_eur',
    'stripe_eur',
    'ccbill_eur',
    'inoviopay_eur',
    'stripebg01_eur',
]

const ACQUIRINGS_FOR_USD = [
    'ccbill_usd'
]

const hasUsdPaymentOptions = (settings) => {
  if (settings?.paymentsCcbillUsd) {
    return true
  }

  return false
}

const hasEurPaymentOptions = (settings) => {
  if (
      settings?.paymentsFlexpay ||
      settings?.paymentsStripe ||
      settings?.paymentsStripebg01 ||
      settings?.paymentsCcbill ||
      settings?.paymentsInoviopay ||
      settings?.paymentsInoviopay3ds2 ||
      settings?.paymentsNetvalve
  ) {
    return true
  }

  return false
}

const hasUsdAcquiringOptions = (acquirings) => {
  return acquirings?.some(item => ACQUIRINGS_FOR_USD.includes(item))
}

const hasEurAcquiringOptions = (acquirings) => {
  return acquirings?.some(item => ACQUIRINGS_FOR_EUR.includes(item))
}

const getAllowedCurrencies = (settings, acquirings) => {
  const currencies = new Set()

  if (acquirings.length) {
    if (hasUsdAcquiringOptions(acquirings)) {
      currencies.add(CURRENCIES_LIST.usd)
    }

    if (hasEurAcquiringOptions(acquirings)) {
      currencies.add(CURRENCIES_LIST.eur)
    }
  } else {
    if (hasUsdPaymentOptions(settings)) {
      currencies.add(CURRENCIES_LIST.usd)
    }

    if (hasEurPaymentOptions(settings)) {
      currencies.add(CURRENCIES_LIST.eur)
    }
  }

  if (currencies.size < 1) {
    currencies.add(CURRENCIES_LIST.eur)
  }

  return Array.from(currencies)
}

const getPreferredCurrency = (currencies) => {
  if (currencies.includes(CURRENCIES_LIST.usd)) {
    return CURRENCIES_LIST.usd
  }

  return CURRENCIES_LIST.eur
}

const Credits = () => {
  const stylesOverrides = useMemo(
    () => ({
      contentWrapper: {
        padding: 0,
        maxWidth: 968,
      },
    }),
    []
  );

  const classes = useStyles();
  const width = useContainerWidth();

  const settings = useAppSelector<ISettings>((state) => state.users.settings);
  const acquiring = useAppSelector<string[]>((state) => state.users.acquiring);

  const allowedCurrencies = getAllowedCurrencies(settings, acquiring);
  const showCurrencySwitcher = allowedCurrencies.length > 1
  const preferredCurrency = getPreferredCurrency(allowedCurrencies);
  const [currentCurrency, setCurrentCurrency] = useState(CURRENCIES_LIST.eur);

  const [chosenCreditpack, setChosenCreditPack] = useState(null);
  const [isDialogOpen, openDialog, hideDialog] = useBinarySwitcher();
  const [isDialogPriceListOpen, openPriceListDialog, hidePriceListDialog] = useBinarySwitcher();

  const setCreditPackHandler = useCallback(
    (creditPack) => {
      setChosenCreditPack(creditPack);
      openDialog();
    },
    [openDialog]
  );

  const hideDialogHandler = useCallback(() => {
    setChosenCreditPack(null);
    hideDialog();
  }, [hideDialog]);

  const [creditPacks, setCreditPacks] = useState([]);

  useEffect(() => {
    setCurrentCurrency(preferredCurrency)
  }, [preferredCurrency])

  useEffect(() => {
    const fetchCreditPacks = async () => {
      const response = await CreditsService.getCreditPacks();

      setCreditPacks(response.filter((item) => item.isActive));
    };

    fetchCreditPacks();
  }, []);

  const getSale = useCallback(
    ({ priceUSD, priceEUR, oldPriceUSD, oldPriceEUR }) => {
      const currentPrice = currentCurrency === CURRENCIES_LIST.usd ? priceUSD : priceEUR
      const currentOldPrice = currentCurrency === CURRENCIES_LIST.usd ? oldPriceUSD : oldPriceEUR
      if (!currentOldPrice || currentPrice === currentOldPrice) return null;

      return <div className={classes.sale}>-{Math.round(((currentOldPrice - currentPrice) / currentOldPrice) * 100)} %</div>;
    },
    [classes.sale, currentCurrency]
  );

  const getSign = useCallback(
    (creditPack) => {
      if (creditPack.limit) return <div className={classes.sale}>PROMO</div>;

      return getSale(creditPack);
    },
    [classes.sale, getSale]
  );

  const openPriceListFromDialogBuyCredits = useCallback(() => {
    openPriceListDialog();
  }, [openPriceListDialog]);

  const getCurrencySign = () => {
    if (currentCurrency === CURRENCIES_LIST.usd) {
      return '$'
    }

    return '€'
  }

  const getActualPrice = (creditPack) => {
    const price = currentCurrency === CURRENCIES_LIST.usd ? creditPack.priceUSD : creditPack.priceEUR

    return `${Number(price).toFixed(2)} ${getCurrencySign()}`
  }

  const getOldPrice = (creditPack) => {
    const price = currentCurrency === CURRENCIES_LIST.usd ? creditPack.oldPriceUSD : creditPack.oldPriceEUR

    return `${Number(price).toFixed(2)} ${getCurrencySign()}`
  }

  const hasOldPrice = (creditPack) => {
    if (currentCurrency === CURRENCIES_LIST.usd && creditPack?.oldPriceUSD) {
      return creditPack?.oldPriceUSD !== creditPack?.priceUSD
    }

    if (currentCurrency === CURRENCIES_LIST.eur && creditPack?.oldPriceEUR) {
      return creditPack?.oldPriceEUR !== creditPack?.priceEUR
    }

    return false
  }

  return (
    <Container maxWidth={width} className={classes.contentWrapper} style={stylesOverrides.contentWrapper}>
      {isDialogOpen && (
        <DialogBuyCredits
          openPriceListFromDialogBuyCredits={openPriceListFromDialogBuyCredits}
          open={isDialogOpen}
          closeHandler={hideDialogHandler}
          chosenCreditpack={chosenCreditpack}
          currency={currentCurrency}
        />
      )}
      <DialogPriceList open={isDialogPriceListOpen} closeHandler={hidePriceListDialog} />

      <BaseCard wrapperOverrideStyle={{ margin: 0, paddingBottom: 10 }}>
        <div className={classes.cardWrapper}>
          <header className={classes.header}>
            <Typography variant='h2'>Credits</Typography>
            <BaseButton
              inverted
              text='Price list'
              className={classes.priceListBtn}
              textStyle={classes.priceListTextStyle}
              Icon={<PriceListIcon className={classes.icon} />}
              onClick={openPriceListDialog}
            />
          </header>
        </div>
      </BaseCard>

      {showCurrencySwitcher && (
        <Box className={classes.currencySwitcherWrapper}>
          <ToggleButtonGroup
              color="primary"
              value={currentCurrency}
              exclusive
              onChange={(e, value) => setCurrentCurrency(value)}
              aria-label="currency"
          >
            <ToggleButton className={classes.currencySwitcherLeftButton} size='small' value="usd">USD</ToggleButton>
            <ToggleButton className={classes.currencySwitcherRightButton} size='small' value="eur">EUR</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}

      <div className={classes.bodyWrapper}>
        {creditPacks.map((creditPack) => (
          <CreditCard key={creditPack.id}>
            <div className={classes.creditPackWrapper}>
              <img src={creditPack.image} alt='creditPack' className={classes.creditPackImage} />
              <div className={classes.infoBlock}>
                {getSign(creditPack)}
                <div className={classes.name}>{creditPack.name}</div>
                <Divider style={{ height: 1 }} sx={{ width: '75%' }} />
                <div className={classes.price}>
                  {creditPack.credits}
                  <div className={classes.verticalDivider} />
                  <div className={classes.actualPrice}>{getActualPrice(creditPack)}</div>
                  {<div className={classes.oldPrice}>{hasOldPrice(creditPack) ? getOldPrice(creditPack) : null}</div>}
                </div>
                <BaseButton
                  text={'Buy'}
                  color='primary'
                  className={classes.buyButton}
                  onClick={() => setCreditPackHandler(creditPack)}
                />
              </div>
            </div>
          </CreditCard>
        ))}
      </div>
    </Container>
  );
};

export default Credits;
