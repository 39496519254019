import { useAppSelector } from '@/app/hooks';
import { SignupDto } from '@/services/auth.service';
import MaleIcon from '@/ui/wd-male';
import FemaleIcon from '@/ui/wd-woman.ui';
import { signUpSchema } from '@/utils/validationSchemas';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  List,
  RadioGroup,
  Theme,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Formik } from 'formik';
import React, { Fragment, useState } from 'react';

import BaseButton from '../base/button.component';
import BaseTextField from '../base/text-field.component';
import ReCAPTCHA from 'react-google-recaptcha';
import { UserProfile } from '@/types/user-profile.types';
import SignInLayout from '../dialogs/sign-in.component';
//TODO: button background color should be #eee
//TODO: refactor

type RegistrationLayoutProps = {
  onSubmit: (values: SignupDto) => void;
  referenceProfile: UserProfile;
  closeHandler: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  smt: {
    cursor: 'pointer',
    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: '12px !important',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  formContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  dialogTitle: {
    textAlign: 'center',
    height: 48,
  },
  formLabelText: {
    fontSize: 14,
    color: '#000',
  },
  formLabelButton: {
    '&:focus': {
      outline: `2px solid ${theme.palette.radioSelected.main}`,
      outlineOffset: 2,
    },
  },
  genderForm: {
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > $formRadioContainer': {
      marginTop: 20,
    },
    '& > $formRadioContainer:last-of-type': {
      [theme.breakpoints.down(405)]: {
        marginLeft: 10,
      },
    },
  },
  formRadioContainer: {},
  formTextFieldsContainer: {
    marginTop: 20,
    marginBottom: 20,
    '& > $formTextField:not(:first-child)': {
      marginTop: 20,
    },
  },
  formTextField: {},
  formActions: {
    marginTop: 30,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formRegistrationBtn: {
    width: '100%',
    maxWidth: '200px',
  },
  listItem: {
    paddingRight: '10px',
  },
  captchaWrapper: {
    margin: '20px auto 0',
  },
  modalWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  registrationWrapper: {
    paddingLeft: '0px',
  },
  userAvatarWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  boxWrapper: {
    width: '100%',
  },
}));

const RegistrationLayout = ({ onSubmit, referenceProfile, closeHandler }: RegistrationLayoutProps) => {
  const [tabValue, setTabValue] = useState(0); // 0 for SignUp, 1 for SignIn

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const classes = useStyles();
  const isLoading = useAppSelector((state) => state.auth.authIsLoading);

  const theme = useTheme();
  const selectedButtonColor = theme.palette.radioSelected.main;
  const policiesItems = [
    {
      path: '/refund-policy',
      name: 'Refund Policy',
    },
    {
      path: '/privacy-policy',
      name: 'Privacy Policy',
    },
    {
      path: '/terms-of-use',
      name: 'Terms of use',
    },
  ];

  const handleClick = (link: string) => {
    window.open(link);
  };

  const [captchaValue, setCaptchaValue] = useState(null);

  const onChange = (value: any) => setCaptchaValue(value);

  return (
    <Fragment>
      <div className={classes.modalWrapper}>
        <div className={classes.userAvatarWrapper}>
          <img width={450} src={referenceProfile.avatar} alt='avatar' />
        </div>
        <Box className={classes.boxWrapper}>
          <Tabs value={tabValue} onChange={handleChangeTab} centered>
            <Tab label='Registration' />
            <Tab label='Sign In' />
          </Tabs>

          {tabValue === 0 && (
            <div className={classes.registrationWrapper}>
              <div className={classes.dialogTitle}></div>

              <Formik
                initialValues={{
                  gender: '',
                  name: '',
                  email: '',
                  password: '',
                  agreed: false,
                  ...referenceProfile ? { referralProfileId: referenceProfile.id } : {},
                }}
                validationSchema={signUpSchema}
                onSubmit={({ agreed, ...values }, { setSubmitting }) => {
                  onSubmit(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  isValid,
                  dirty,
                }) => (
                  <form onSubmit={handleSubmit} className={classes.formContainer}>
                    {/* <div>{JSON.stringify(values)}</div> */}
                    <FormControl>
                      <RadioGroup row className={classes.genderForm}>
                        <FormControlLabel
                          className={classes.formRadioContainer}
                          disableTypography
                          name='gender'
                          value='male'
                          control={<></>}
                          label={
                            <Button
                              disableElevation
                              color={values.gender === 'male' ? 'radioSelected' : 'inherit'}
                              style={{
                                backgroundColor: values.gender === 'male' ? selectedButtonColor : '#eee',
                              }}
                              className={classes.formLabelButton}
                              startIcon={<MaleIcon color={values.gender === 'male' ? '#fff' : undefined} />}
                              onClick={() => setFieldValue('gender', 'male')}
                            >
                              <span
                                className={classes.formLabelText}
                                style={{
                                  color: values.gender === 'male' ? '#fff' : '#503EB6',
                                }}
                              >
                                I Am Man
                              </span>
                            </Button>
                          }
                        />
                        <FormControlLabel
                          className={classes.formRadioContainer}
                          disableTypography
                          value='female'
                          control={<></>}
                          label={
                            <Button
                              disableElevation
                              color={values.gender === 'female' ? 'radioSelected' : 'inherit'}
                              style={{
                                backgroundColor: values.gender === 'female' ? selectedButtonColor : '#eee',
                                color: values.gender === 'female' ? '#fff' : 'inherit',
                              }}
                              className={classes.formLabelButton}
                              startIcon={<FemaleIcon color={values.gender === 'female' ? '#fff' : undefined} />}
                              onClick={() => setFieldValue('gender', 'female')}
                            >
                              <span
                                style={{
                                  color: values.gender === 'female' ? '#fff' : '#503EB6',
                                }}
                                className={classes.formLabelText}
                              >
                                I Am Woman
                              </span>
                            </Button>
                          }
                        />
                      </RadioGroup>
                      <div className={classes.formTextFieldsContainer}>
                        <BaseTextField
                          label='name'
                          onChange={handleChange}
                          type='text'
                          placeholder={'name'}
                          baseClass={classes.formTextField}
                        />
                        <BaseTextField
                          label='email'
                          onChange={handleChange}
                          type='email'
                          placeholder={'email'}
                          baseClass={classes.formTextField}
                        />
                        <BaseTextField
                          label='password'
                          onChange={handleChange}
                          type='password'
                          placeholder={'password'}
                          baseClass={classes.formTextField}
                        />
                      </div>

                      <List className={clsx(classes.row)}>
                        {policiesItems.map((link, index) => (
                          <li key={link.name} className={classes.listItem}>
                            <Typography variant='body2' className={classes.smt} onClick={() => handleClick(link.path)}>
                              {link.name}
                              {index === policiesItems.length - 1 ? '' : ', '}
                            </Typography>
                          </li>
                        ))}
                      </List>

                      <FormControlLabel
                        control={
                          <Checkbox name='agreed' color='warning' onChange={handleChange} checked={values.agreed} />
                        }
                        label='I Agree With Terms Of Use, Refund Police, Private Police. I`m 18 or older.'
                      />
                      {touched.agreed && errors.agreed && <FormHelperText error={true}>{errors.agreed}</FormHelperText>}

                      <div className={classes.captchaWrapper}>
                        <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY as string} onChange={onChange} />
                      </div>

                      <div className={classes.formActions}>
                        <BaseButton
                          loading={isLoading}
                          type='submit'
                          color='primary'
                          text='Join Now'
                          className={classes.formRegistrationBtn}
                          disabled={!(isValid && dirty && captchaValue)}
                        />
                      </div>
                    </FormControl>
                  </form>
                )}
              </Formik>
            </div>
          )}

          {tabValue === 1 && <SignInLayout closeHandler={closeHandler} />}
        </Box>
      </div>
    </Fragment>
  );
};

export default RegistrationLayout;
