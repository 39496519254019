import { HttpService } from "./http.service";
import { ErrorWrapper } from "./utils";

export interface PaymentsDto {
  id: string;
  profileId: string;
  creditPackId: string;
  creditPackName: string;
  gateway: string;
  price: number;
  priceUSD: number;
  priceEUR: number;
  credits: number;
  createdAt: string | null;
}

const mapResponce = (data: Array<PaymentsDto>) => data.map(item => ({
  creditPackId: item.creditPackId,
  creditPackName: item.creditPackName,
  credits: item.credits,
  price: ['ccbill_usd'].includes(item.gateway) ? `${item.priceUSD}$` : `${item.priceEUR}€`,
  createdAt: item.createdAt,
}))

const getQueryParams = (offset, sortBy, sortOrder) => {
  if (!offset && !sortBy && !sortOrder) return '';

  return `?limit=20&offset=${offset}${sortBy && sortOrder ? `&sortField=${sortBy}&sortOrder=${sortOrder.toUpperCase()}` : ''}`
}

export default class PaymentsService {
  static get entity(): string {
    return "payments";
  }

  static async getPayments(
    offset: number,
    sortBy: string,
    sortOrder: "asc" | "desc" | null,
  ): Promise<Array<PaymentsDto>> {
    try {
      const response = await HttpService.get(`/${this.entity}${getQueryParams(offset, sortBy, sortOrder)}`);

      //@ts-ignore
      return mapResponce(response.data);
    } catch (error: any) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
